import type { GetPickersResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/pickers'
import type { PaymentMethod } from '@backmarket/http-api/src/api-specs-payment/payment/payment.types'

/**
 * Filter a list of payment methods by removing those disabled for AB testing (remove Klarna or Affirm)
 */
export function filterABTestedPaymentMethods(
  experiments: Record<string, string>,
  paymentMethods: PaymentMethod[],
) {
  const filter = (methods: PaymentMethod[], experimentVariation: string) => {
    switch (experimentVariation) {
      case 'withoutClearpay':
        return methods.filter((method) => method.group !== 'clearpay')
      case 'withoutOney':
        return methods.filter((method) => method.group !== 'oney')
      case 'withoutKlarna':
        return methods.filter(
          (method) =>
            method.group !== 'klarna_pay_later' &&
            method.group !== 'klarna_slice_it',
        )
      default:
        return methods
    }
  }

  let filtered = paymentMethods
  filtered = filter(filtered, experiments['experiment.killOneyFr'])
  filtered = filter(filtered, experiments['experiment.enableClearpayUK'])
  filtered = filter(filtered, experiments['experiment.enableKlarnaIT'])

  return filtered
}

/**
 * remove payement methods that are disabled for AB testing.
 */
export function filterSelectedOfferABTestedPaymentMethods(
  experiments: Record<string, string>,
  selectedOffer: NonNullable<GetPickersResponse['selectedOffer']>,
) {
  if (!selectedOffer.paymentMethods) {
    return selectedOffer
  }

  return {
    ...selectedOffer,
    paymentMethods: filterABTestedPaymentMethods(
      experiments,
      selectedOffer.paymentMethods,
    ),
  }
}
